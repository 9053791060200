.falta-horas {
  display: flex;
  margin-top: 20px;
  width: 100%;
}

.faltaDiario {
  width: 33%;
}

.faltaPeriodo {
  width: 33%;
}

.horas {
  display: flex;
  width: 67%;
}

.tipoPeriodo {
  width: 48%;
}

.autocompletes {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.horasBorderDiario {
  width: 800px;
  height: 40px;
  text-align: center;
  border: 1px solid #f88e7d;
  border-radius: 5px;
  padding: 8px;
}
.horasBorderPeriodo {
  width: 80px;
  height: 40px;
  text-align: center;
  border: 1px solid #f88e7d;
  border-radius: 5px;
  padding: 8px;
}
.horasBorderPeriodoTextfield {
  width: 80px;
  height: 40px;
  text-align: center;
  border: 1px transparent #f88e7d;
  border-radius: 5px;
  /* padding: 8px; */
}

.periodo-atrasos-true {
  width: 48.2%;
}
.periodo-atrasos-false {
  width: 100%;
}
.data-fim-periodo {
  width: 100%;
  margin-left: 14px;
  background: 'pink';
}

@media only screen and (max-width: 800px) {
  .falta-horas {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;
    width: 100%;
  }
  .falta {
    width: 100%;
    margin-top: 10px;
  }
  .horas {
    width: 100%;
  }
  .tipoPeriodo {
    width: 100%;
    margin-bottom: 20px;
  }
  .modalScrool {
    max-height: 80vh;
    overflow-y: scroll;
  }
  .autocompletes {
    flex-direction: column;
  }
  .periodo-atrasos-true {
    width: 100%;
  }
  .horasBorderPeriodo {
    width: 55px;
    height: 40px;
    text-align: center;
    border: 1px solid #f88e7d;
    border-radius: 5px;
    padding: 8px;
  }
}
