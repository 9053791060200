/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
body {
  margin: 0;
  font-family: lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: 'Panton-SemiBold';
  src: local('Panton-SemiBold'), url('./assets/fonts/Panton/Panton-SemiBold.otf') format('opentype');
  /* font-weight: bold; */
}

.font-face-Panton-SemiBold {
  font-family: 'Panton-SemiBold';
}
.text-underline-title {
  position: relative;
  padding-bottom: 5px;
  color: #e21450;
  font-weight: bold;
}
.text-underline-title-dark {
  position: relative;
  color: #000;
  margin-left: 5px;
  font-weight: bold;
}

.text-underline {
  position: relative;
  padding-bottom: 5px;
}
.text-underline::before {
  display: block;
  content: '';
  width: 60px;
  height: 2px;
  background: #e21450;
  position: absolute;
  bottom: 0;
  left: 0;
}
.text-underline-close-drawer {
  position: relative;
  margin-bottom: 10px;
}
.text-underline-close-drawer::before {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background: #707070;
  position: absolute;
  bottom: 0;
  left: 0;
}
.text-underline-title-drawer {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.text-underline-title-drawer::before {
  display: block;
  content: '';
  width: 100%;
  height: 2px;
  background: #707070;
  position: absolute;
  bottom: 0;
  left: 0;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Links */

.link-div {
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  color: #000;
}

@media (max-width: 800px) {
  .link-div {
    color: white;
  }
}
