.permissao-checkbox {
  width: 33%;
}
.permissao-title {
  font-size: 14px;
}
.permissoes-lista {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.permissoes-lista-items {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  .permissao-checkbox {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .permissao-checkbox {
    width: 100%;
  }
}
