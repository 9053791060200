.home {
  display: flex;
  height: 100%;
}
.home-right {
  width: 40%;
  /* background-color: lightsalmon; */
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 25vh;
}

.home-left {
  width: 60%;
  min-height: 100vh;
  background-image: url('../../../assets/login.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: cover;
  z-index: 10;
}

.left-content {
  height: 100%;
  display: flex;
  align-items: flex-end;
  color: white;
  /* margin: 0px 0px 60px 40px; */
}

.left-content-inside {
  /* background: salmon; */
  display: flex;
  /* align-items: flex-end; */
  flex-direction: column;
  padding: 100px;
}
.left-content-inside p {
  font-size: 40px;
  font-weight: 700;
  /* width: 50%; */
  margin: 0;
  /* padding: 40px; */
}

.logo {
  width: 45%;
  padding: 50px;
}

.titulo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* text-align: center; */
  width: 100%;
  color: #000;
}
.titulo p {
  font-size: 20px;
  color: #000;
  text-align: initial;
  font-family: 'Panton';
}
.bottom {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  text-align: start;
  font-size: 10px;
  margin: auto;
}

.loginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;
}
.loginForm p {
  margin-bottom: 20px;
  text-align: left;
  font-size: 16px;
}
.emailInput {
  margin-bottom: 20px !important;
}

.login-form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.login-submit-section {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #707070;
}

.login-bottom-text {
  color: black;
}

@media (max-width: 800px) {
  .home {
    min-height: 100vh;
    background-image: url('../../../assets/login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    background-size: cover;
    z-index: 10;
  }
  .home-right {
    width: 100%;
  }
  .home-left {
    width: 0%;
    display: none;
    /* display: flex; */
  }

  .login-form {
    width: 60%;
  }
  .titulo {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* text-align: center; */
    width: 100%;
    color: white;
  }
  .titulo p {
    color: white;
    text-align: center;
  }

  .login-submit-section {
    align-items: center;
    color: white;
  }
  .login-submit-section p {
    color: white;
  }
  .login-bottom-text {
    color: white;
  }
}
